<template>
  <div class="permission-view">
    <div class="d-flex flex-wrap">
      <b-card :title="$t('permissions.Devices')" class="flex-fill">
        <b-form-checkbox
          v-for="permission in allPermissions.devicePermissions"
          :key="permission.value"
          switch
          size="lg"
          :value="permission.value"
          v-model="changedPermissions"
          :disabled="
            (permission.value != 'reflector.device.*' &&
              changedPermissions.includes('reflector.device.*')) ||
            !editable
          "
          ><i
            v-if="permission.icon"
            :class="`fas fa-${permission.icon} mr-2`"
          ></i
          >{{ $t(`permissions.${permission.name}`) }}</b-form-checkbox
        >
      </b-card>
      <b-card :title="$t('permissions.Administration')" class="flex-fill">
        <b-form-checkbox
          v-for="permission in allPermissions.adminPermissions"
          :key="permission.value"
          switch
          size="lg"
          :value="permission.value"
          v-model="changedPermissions"
          :disabled="!editable"
          ><i
            v-if="permission.icon"
            :class="`fas fa-${permission.icon} mr-2`"
          ></i
          >{{ $t(`permissions.${permission.name}`) }}</b-form-checkbox
        >
      </b-card>
    </div>
    <b-card :title="$t('permissions.visibleDevices')">
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          flex-wrap-reverse
          mb-3
        "
      >
        <b-form-checkbox
          v-for="permission in allPermissions.visibleDevices"
          :key="permission.value"
          switch
          size="lg"
          :value="permission.value"
          v-model="changedPermissions"
          :disabled="!editable"
          ><i
            v-if="permission.icon"
            :class="`fas fa-${permission.icon} mr-2`"
          ></i
          >{{ $t(`permissions.${permission.name}`) }}</b-form-checkbox
        >
        <b-form inline @submit.prevent="addDevice" v-if="editable">
          <vue-single-select
            class="mr-2"
            v-model="selectedDevice"
            :options="
              getRawNameList.filter((i) => {
                return !this.visibleDevices.includes(i.id);
              })
            "
            option-label="title"
            :placeholder="$t('permissions.enterDeviceName')"
          ></vue-single-select>

          <b-button variant="outline-secondary" pill type="submit"
            ><i class="fas fa-plus mr-2"></i
            >{{ $t("permissions.addDevice") }}</b-button
          >
        </b-form>
      </div>

      <ul
        class="list-group"
        v-if="
          changedPermissions &&
          !changedPermissions.includes('reflector.device:*')
        "
      >
        <li class="list-group-item" v-for="id in visibleDevices" :key="id">
          <div class="d-flex justify-content-between align-items-center">
            <span>{{ getDeviceNameFromId(id) }}</span>
            <b-button pill variant="outline-danger" @click="removeDevice(id)"
              ><i class="fas fa-trash"></i
            ></b-button>
          </div>
        </li>
      </ul>
      <div class="d-flex justify-content-end mt-2" v-if="editable">
        <b-button
          variant="primary"
          size="lg"
          @click="$emit('save', changedPermissions)"
          ><i class="fas fa-save mr-2"></i
          >{{ $t("permissions.savePermissions") }}</b-button
        >
        <b-button variant="danger" size="lg" class="ml-2" @click="cancel"
          ><i class="fas fa-times mr-2"></i
          >{{ $t("permissions.cancel") }}</b-button
        >
      </div>
    </b-card>
  </div>
</template>

<script>
import VueSingleSelect from "vue-single-select";

import { mapGetters } from "vuex";

export default {
  props: {
    permissions: Array,
    editable: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    VueSingleSelect,
  },
  data() {
    return {
      changedPermissions: [...this.permissions],
      visibleDevices: [],
      selectedDevice: null, // {id: '<id>', title: '<full name>'}
      allPermissions: {
        devicePermissions: [
          {
            name: "canAll",
            value: "reflector.device.*",
            icon: "asterisk",
          },
          {
            name: "canCreate",
            value: "reflector.device.create",
            icon: "plus",
          },
          {
            name: "canUpdate",
            value: "reflector.device.update",
            icon: "edit",
          },
          {
            name: "canDelete",
            value: "reflector.device.delete",
            icon: "trash",
          },
          {
            name: "showDeviceState",
            value: "eos.device.showDeviceState",
            icon: "info-circle",
          },
        ],
        adminPermissions: [
          {
            name: "authAdmin",
            value: "authAdmin",
          },
          {
            name: "bundleAdmin",
            value: "bundleAdmin",
          },
          {
            name: "domainAdmin",
            value: "domainAdmin",
          },
        ],
        visibleDevices: [
          {
            name: "showAll",
            value: "reflector.device:*",
          },
        ],
      },
    };
  },
  methods: {
    addDevice() {
      if (!this.selectedDevice) return;
      this.changedPermissions.push(
        `reflector.device:${this.selectedDevice.id}`
      );
      this.calculateVisibleDevices();
      this.selectedDevice = null;
    },
    removeDevice(id) {
      this.changedPermissions.splice(
        this.changedPermissions.indexOf(`reflector.device:${id}`),
        1
      );
      this.calculateVisibleDevices();
    },
    calculateVisibleDevices() {
      this.visibleDevices = this.changedPermissions
        .filter((p) => {
          return p.startsWith("reflector.device:") && !p.endsWith("*");
        })
        .map((p) => {
          return p.split(":")[1];
        });
    },
    cancel() {
      this.changedPermissions = [...this.permissions];
      this.calculateVisibleDevices();
    },
  },

  computed: {
    ...mapGetters("devices", ["getDeviceNameFromId", "getRawNameList"]),
  },
  watch: {
    permissions: function (newVal) {
      // console.log("CHANGED PERMISSIONS: ");
      // console.log("new: ", newVal);
      this.changedPermissions = [...newVal];
      this.calculateVisibleDevices();
    },
  },
};
</script>

<style>
</style>