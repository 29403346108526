var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"permission-view"},[_c('div',{staticClass:"d-flex flex-wrap"},[_c('b-card',{staticClass:"flex-fill",attrs:{"title":_vm.$t('permissions.Devices')}},_vm._l((_vm.allPermissions.devicePermissions),function(permission){return _c('b-form-checkbox',{key:permission.value,attrs:{"switch":"","size":"lg","value":permission.value,"disabled":(permission.value != 'reflector.device.*' &&
            _vm.changedPermissions.includes('reflector.device.*')) ||
          !_vm.editable},model:{value:(_vm.changedPermissions),callback:function ($$v) {_vm.changedPermissions=$$v},expression:"changedPermissions"}},[(permission.icon)?_c('i',{class:("fas fa-" + (permission.icon) + " mr-2")}):_vm._e(),_vm._v(_vm._s(_vm.$t(("permissions." + (permission.name)))))])}),1),_c('b-card',{staticClass:"flex-fill",attrs:{"title":_vm.$t('permissions.Administration')}},_vm._l((_vm.allPermissions.adminPermissions),function(permission){return _c('b-form-checkbox',{key:permission.value,attrs:{"switch":"","size":"lg","value":permission.value,"disabled":!_vm.editable},model:{value:(_vm.changedPermissions),callback:function ($$v) {_vm.changedPermissions=$$v},expression:"changedPermissions"}},[(permission.icon)?_c('i',{class:("fas fa-" + (permission.icon) + " mr-2")}):_vm._e(),_vm._v(_vm._s(_vm.$t(("permissions." + (permission.name)))))])}),1)],1),_c('b-card',{attrs:{"title":_vm.$t('permissions.visibleDevices')}},[_c('div',{staticClass:"\n        d-flex\n        justify-content-between\n        align-items-center\n        flex-wrap-reverse\n        mb-3\n      "},[_vm._l((_vm.allPermissions.visibleDevices),function(permission){return _c('b-form-checkbox',{key:permission.value,attrs:{"switch":"","size":"lg","value":permission.value,"disabled":!_vm.editable},model:{value:(_vm.changedPermissions),callback:function ($$v) {_vm.changedPermissions=$$v},expression:"changedPermissions"}},[(permission.icon)?_c('i',{class:("fas fa-" + (permission.icon) + " mr-2")}):_vm._e(),_vm._v(_vm._s(_vm.$t(("permissions." + (permission.name)))))])}),(_vm.editable)?_c('b-form',{attrs:{"inline":""},on:{"submit":function($event){$event.preventDefault();return _vm.addDevice($event)}}},[_c('vue-single-select',{staticClass:"mr-2",attrs:{"options":_vm.getRawNameList.filter(function (i) {
              return !this$1.visibleDevices.includes(i.id);
            }),"option-label":"title","placeholder":_vm.$t('permissions.enterDeviceName')},model:{value:(_vm.selectedDevice),callback:function ($$v) {_vm.selectedDevice=$$v},expression:"selectedDevice"}}),_c('b-button',{attrs:{"variant":"outline-secondary","pill":"","type":"submit"}},[_c('i',{staticClass:"fas fa-plus mr-2"}),_vm._v(_vm._s(_vm.$t("permissions.addDevice")))])],1):_vm._e()],2),(
        _vm.changedPermissions &&
        !_vm.changedPermissions.includes('reflector.device:*')
      )?_c('ul',{staticClass:"list-group"},_vm._l((_vm.visibleDevices),function(id){return _c('li',{key:id,staticClass:"list-group-item"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('span',[_vm._v(_vm._s(_vm.getDeviceNameFromId(id)))]),_c('b-button',{attrs:{"pill":"","variant":"outline-danger"},on:{"click":function($event){return _vm.removeDevice(id)}}},[_c('i',{staticClass:"fas fa-trash"})])],1)])}),0):_vm._e(),(_vm.editable)?_c('div',{staticClass:"d-flex justify-content-end mt-2"},[_c('b-button',{attrs:{"variant":"primary","size":"lg"},on:{"click":function($event){return _vm.$emit('save', _vm.changedPermissions)}}},[_c('i',{staticClass:"fas fa-save mr-2"}),_vm._v(_vm._s(_vm.$t("permissions.savePermissions")))]),_c('b-button',{staticClass:"ml-2",attrs:{"variant":"danger","size":"lg"},on:{"click":_vm.cancel}},[_c('i',{staticClass:"fas fa-times mr-2"}),_vm._v(_vm._s(_vm.$t("permissions.cancel")))])],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }